import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'

import '../css/Sobre.css'

import Section from '../components/Section'
import { TextBox, TextBoxImg, TextBoxContent, TextBoxListItem, TextBoxListTitle, TextDoubleBox, TextDoubleBoxContent, TextBoxListTxt } from '../components/TextBox'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'


import Sobre_img1 from '../media/sobre/sobre_1.png'
import Visao from '../media/sobre/visao.png'
import Podemos from '../media/sobre/ajuda.png'
import Valores from '../media/sobre/valores.png'
import Missao from '../media/sobre/missao.png'

function Sobre(props){

    function CardContainer(props){

        function Card(props){

            return(

                <ul className = "cardBox">
                    <FontAwesomeIcon className = "iconWhatsapp" icon = {faWhatsapp} padding="2px"/>
                    <a className = "cardTitle"> {props.title} </a>     
                </ul>

            )

        }

        return(

            <a className = "cardContainer" href="https://api.whatsapp.com/send?phone=5511996049933">
                <Card title = "Entre em contato" />
            </a>

        )

    }

    return(

        <div className = "mainContent">

            <Section id = "Hero">

                <TextBox>

                    <TextBoxContent
                        type = "2"
                        aos = "fade-right"
                        title = "Sobre nós"
                        text = "Somos uma empresa focada em fornecer tecnologia para melhorar a gestão dos nossos clientes, atuamos de forma estratégica para oferecer soluções em software para as áreas de Gestão Empresarial, Logística, Varejo, Gestão de Acesso e Segurança."
                    />
                    <TextBoxImg img = {Sobre_img1} />

                </TextBox>

            </Section>

        
            <Section id = "About1">

                <div className = "aboveBox">

                    <TextBox>
        
                        <TextBoxImg img = {Missao} />
                        <TextBoxContent 
                            type = "2"
                            aos = "fade-left"
                            title = " Nossa Missão" 
                            text = "Entender as necessidades de nossos clientes e oferecer as melhores soluções no mercado, conceder aos clientes um atendimento de qualidade e um acompanhamento técnico especializado."
                        />      


                    </TextBox>

                </div>

            </Section>

            <Section id = "About2">

                <TextBox>

                    <TextBoxContent 
                        type = "2" 
                        aos = "fade-right"
                        title = " Nossa Visão" 
                        text = "Tornar-se referência nacional no ramo de Softwares e Aplicações Mobile."
                    />      
                    <TextBoxImg img = {Visao} />

                </TextBox>

            </Section>

            <Section id = "about3">

                <TextBox>

                    <TextBoxImg img = {Valores} />

                    <TextBoxContent
                        type = "4" 
                        aos = "fade-left"
                        title = "Nosso Valores"
                    >

                        <TextBoxListItem txt = "Ética e respeito ao cliente" />
                        <TextBoxListItem txt = "Comprometimento" />
                        <TextBoxListItem txt = "Responsabilidade" />
                        <TextBoxListItem txt = "Satisfação em criar soluções" />
                        <TextBoxListItem txt = "Trabalho em equipe e inovação" />
                        <TextBoxListItem txt = "Transparência nas relações empresa x cliente" />

                    </TextBoxContent>

                </TextBox>

            </Section>

            <Section id = "About4">

                <TextBox>

                    <TextBoxContent
                        type = "3"
                        aos = "fade-right" 
                        title = "Podemos te ajudar"
                        text = "Agora que você conhece a Alta Soluções e está pensando em investir em tecnologia para sua empresa, tire suas dúvidas com um de nossos especialistas."
                        btn = "Fale com um especialista"
                        link = "https://api.whatsapp.com/send?phone=5511996049933"
                    />

                    <TextBoxImg img = {Podemos} />

                </TextBox>

            </Section>

        </div>

    )

}

export default Sobre