import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './css/App.css'
import './css/Responsive.css'
import './css/Vars.css'

import Content from './components/Content'
import Index from './pages/Index'
import Produtos from './pages/Produtos'
import { Gestao, Supply, PDV } from './pages/Produto'
import Mobile from './pages/Mobile'
import FSW from './pages/FSW'
import Conteudos from './pages/Conteudos'
import Error from './pages/Error'
import Sobre from './pages/Sobre'

function App(){

  return(

    <Router>

      <Switch>

        {/* Index */}
        <Route path = "/" exact>

          <Content id = "Index" helmet = "Alta Soluções" description = "">
            <Index />
          </Content>

        </Route>

        {/* Mobile */}
        <Route path = "/mobile">

          <Content id = "Mobile" helmet = "Desenvolvimento Mobile Alta Soluções" description = "">
            <Mobile />
          </Content>

        </Route>
        
        {/* FSW */}
        <Route path = "/fabrica-software">

          <Content id = "FSW" helmet = "Fábrica de Software Alta Soluções" description = "">
            <FSW />
          </Content>

        </Route>

        {/* Produtos */}
        <Route path = "/produtos">

          <Content id = "Produtos" helmet = "Produtos Alta Soluções" description = "">
            <Produtos />
          </Content>

        </Route>
        
        {/* Produto */}
        <Route path = "/produto/gestao">

          <Content id = "Produto" helmet = "Gestão Alta Soluções" description = "">
            <Gestao />
          </Content>

        </Route>
        <Route path = "/produto/supply">

          <Content id = "Produto" helmet = "Supply Track Alta Soluções" description = "">
            <Supply />
          </Content>

        </Route>
        <Route path = "/produto/pdv">

          <Content id = "Produto" helmet = "PDV Alta Soluções" description = "">
            <PDV />
          </Content>

        </Route>
        <Route path = "/sobre">

          <Content id = "Sobre" helmet = "Sobre a Alta Soluções" description = "">
            <Sobre />
          </Content>

        </Route>

        {/* Conteudos 
        <Route path = "/conteudos">

          <Content id = "Conteudos" helmet = "Conteudos Alta Soluções">
            <Conteudos />
          </Content>

        </Route>*/}

        {/* Error */}
        <Route exact path = "*">

          <Error helmet = "Página não encontrada" />

        </Route>
        
      </Switch>

    </Router>

  )

}

export default App;