import React from 'react'
import Helmet from 'react-helmet'

import '../css/Mobile.css'

import Section from '../components/Section'
import { TextBox, TextBoxImg, TextBoxContent } from '../components/TextBox'

import Mobile_img1 from '../media/mobile/mobile_1.png'
import Mobile_img2 from '../media/mobile/mobile_2.png'
import Mobile_img3 from '../media/mobile/mobile_3.png'
import Mobile_img4 from '../media/mobile/mobile_4.png'
import Mobile_img5 from '../media/mobile/mobile_5.png'
import Mobile_img6 from '../media/mobile/mobile_6.png'
import Mobile_img7 from '../media/mobile/mobile_7.png'

function Mobile(props){

    return(

        <div className = "mainContent">

            <Section id = "Hero">

                <TextBox>

                    <TextBoxContent type = "2" title = "Transformamos suas ideias em aplicativos de sucesso" />
                    <TextBoxImg img = {Mobile_img3} />

                </TextBox>

            </Section>

            <Section id = "About1">

                <div className = "aboveBox">

                    <TextBox>

                        <TextBoxImg img = {Mobile_img5} />
                        <TextBoxContent 
                            type = "2" 
                            aos = "fade-right"
                            title = "Esteja a frente das transformações digitais no mundo" 
                            text = "Ter um aplicativo é sinônimo de agilidade e alta performance. Desenvolvemos aplicativos de sucesso de pequenas a grandes empresas, buscando sempre inovação e simplicidade, ajudando nossos clientes a desenvolver ideias e construir grandes negócios"
                        />      

                    </TextBox>

                </div>

            </Section>

            <Section id = "About2">

                <TextBox>

                    <TextBoxContent 
                        type = "2"
                        aos = "fade-left"
                        title = "Experiência do Usuário"
                        text = "Não importa o quão complexa seja sua tarefa, seu aplicativo deve se manter simples e intuitivo. Adotamos uma abordagem que valoriza a experiência do usuário, com uma usabilidade simples até em processos mais complicados."
                    />
                    <TextBoxImg img = {Mobile_img7} />

                </TextBox>

            </Section>

            <Section id = "About3">

                <TextBox>

                    <TextBoxImg img = {Mobile_img1} />
                    <TextBoxContent 
                        type = "2"
                        aos = "fade-right"
                        title = "Design"
                        text = "Pensamos na interface do seu aplicativo desde o protótipo até o fim de seu desenvolvimento, contando com um time de especialistas preocupados em entregar um app de fácil utilização e envolvente para o usuário."
                    />
                   

                </TextBox>

            </Section>

            <Section id = "About4">

                <TextBox>

                    <TextBoxContent 
                        type = "2"
                        aos = "fade-left"
                        title = "Funcionalidade"
                        text = "Além de um aplicativo de fácil navegação e pensado no usuário, nos preocupamos com sua funcionalidade. Por isso, nossas soluções são criadas com as tecnologias mais modernas no mercado, e atendem as demandas de cada empresa de forma rápida e funcional."
                    />
                    <TextBoxImg img = {Mobile_img3} />

                </TextBox>

            </Section>

            <Section id = "About5">

                <TextBox>

                    <TextBoxImg img = {Mobile_img6} />
                    <TextBoxContent 
                        type = "2"
                        aos = "fade-right"
                        title = "Escalabilidade"
                        text = "Desenvolvemos aplicações com capacidade para gerenciar uma elevada quantidade de processos, e com potencial para adaptação ao crescimento de tarefas. Seu aplicativo cresce à medida que sua empresa cresce."
                    />

                </TextBox>

            </Section>

            <Section id = "About6">

                <TextBox>

                    <TextBoxContent 
                        type = "2"
                        aos = "fade-left"
                        title = "Suporte"
                        text = "Desenvolvemos o aplicativo de uma forma pensada na inclusão de novas funcionalidades ou exclusão de antigas. O que proporciona uma customização sem dores de cabeça."
                    />
                    <TextBoxImg img = {Mobile_img2} />

                </TextBox>

            </Section>

        </div>

    )

}

export default Mobile