import React from 'react'

function Section(props){

    return(

        <section className = {'section'+props.id}>

            <div className = "sectionContent"> {props.children} </div>

        </section>

    )

}

export default Section