import React from 'react'
import Helmet from 'react-helmet'

import '../css/Produto.css'

import Section from '../components/Section'
import { TextBox, TextBoxImg, TextBoxContent, TextBoxListItem, TextBoxListTitle, TextDoubleBox, TextDoubleBoxContent, TextBoxListTxt } from '../components/TextBox'

import PDV_img1 from '../media/pdv/pdv_2.png'
import PDV_img2 from '../media/pdv/pdv_1.png'
import Supply_img1 from '../media/supply/supply_1.png'
import Supply_img2 from '../media/supply/supply_2.png'
import Gestao_img1 from '../media/gestao/gestao_1.png'

import Gestao_icon1 from '../media/alta-2.png'
import Gestao_icon2 from '../media/gestao/1.png'
import Gestao_icon3 from '../media/gestao/2.png'
import Gestao_icon4 from '../media/gestao/3.png'
import Gestao_icon5 from '../media/gestao/4.png'
import Gestao_icon6 from '../media/gestao/5.png'

export function Gestao(){

    return(

        <div className = "mainContent">

            <Section id = "Hero">

                <TextBox>

                    <TextBoxContent
                        type = "2" 
                        aos = "fade-right"
                        title = "ALTA Gestão"
                        text = "Um software completo, com acesso a módulos que são responsáveis por toda parte gerencial de sua empresa, como: financeiro, fiscal, estoque e outras funções."
                    />
                    <TextBoxImg img = {Gestao_img1} />

                </TextBox>

            </Section>

            <Section id = "About1">

                <TextDoubleBox>

                    <TextDoubleBoxContent 
                        title = "Suporte Experiente" 
                        text = "A Alta Soluções possui profissionais altamente capacitados para prestar o suporte de seus sistemas"
                    />
                    <TextDoubleBoxContent 
                        title = "Acesso Online" 
                        text = "Ao utilizar o Alta Gestão, você pode contar com um sistema que irá te atender de qualquer local"
                    />

                </TextDoubleBox>

            </Section>

            <Section id = "About4">

                <ProdutoSection title = "Funcionalidades">

                    <ProdutoSectionItem txt = "Administração" />
                    <ProdutoSectionItem txt = "Financeiro" />
                    <ProdutoSectionItem txt = "Parceiros" />
                    <ProdutoSectionItem txt = "Entradas" />
                    <ProdutoSectionItem txt = "Saídas" />
                    <ProdutoSectionItem txt = "Fiscal" />
                    
                </ProdutoSection>

            </Section>

            <Section id = "List1 sectionList">

                <TextBox>
                    
                    <TextBoxContent
                        type = "4" 
                        aos = "fade-right"
                        title = "Faça a gestão de diversas empresas em um único local"
                    >
                        <TextBoxListItem txt = "Tenha várias empresas em um único endereço eletrônico" />
                        <TextBoxListItem txt = "Cada empresa possui sua própria tabela de preço" />
                        <TextBoxListItem txt = "Cadastro compartilhado entre unidades" />
                        <TextBoxListItem txt = "Possiblidade de transferir mercadorias entre estoques" />
                    </TextBoxContent>
                    <TextBoxImg img = {Gestao_icon1} />
                </TextBox>

            </Section>

            <Section id = "List2 sectionList">

                <TextBox>

                    <TextBoxImg img = {Gestao_icon2} />

                    <TextBoxContent type = "4" aos = "fade-left" title = "Administração">

                        <TextBoxListTitle txt = "Acesso Personalizado" />
                        <TextBoxListItem txt = "O administrador pode definir a permissão de cada usuário" />
                        <TextBoxListItem txt = "Permissão personalizada por grupo de usuários" />


                        <TextBoxListTitle txt = "Condição de PDV's" />
                        <TextBoxListItem txt = "É possível acompanhar a atualização de dados de processamento de pdv’s" />

                    </TextBoxContent>

                </TextBox>

            </Section>

            <Section id = "List3 sectionList">

                <TextBox>

                    <TextBoxContent type = "4" aos = "fade-right" title = "Financeiro">

                        <TextBoxListTxt txt = "Todo seu controle financeiro em um único local" />

                        <TextBoxListTitle txt = "Configuração de Empresas" />
                        <TextBoxListItem txt = "Configure as formas de pagamento e condução de faturamento" />

                        <TextBoxListTitle txt = "Faturamento Agrupado" />
                        <TextBoxListItem txt = "Organize produtos com condições especiais em grupos" />

                        <TextBoxListTitle txt = "Forma de Pagamento" />
                        <TextBoxListItem txt = "Faça o cadastro de quantas formas de pagamento achar melhor" />

                    </TextBoxContent>

                    <TextBoxImg img = {Gestao_icon3} />

                </TextBox>

            </Section>

            <Section id = "List4 sectionList">

                <TextBox>
                    <TextBoxImg img = {Gestao_icon4} />
                    <TextBoxContent
                        type = "4" 
                        aos = "fade-left"
                        title = "Parceiros"
                    >
                        <TextBoxListItem txt = "Facilidade para fazer cadastro" />
                        <TextBoxListItem txt = "Tenha todas as entidades envolvidas cadastradas, como: clientes, fornecedores, funcionários, potencial cliente e parceiros" />
                        <TextBoxListItem txt = "Cadastro entre empresas compartilhado" />
                        <TextBoxListItem txt = "É possível cadastrar diversos endereços e contatos" />
                    </TextBoxContent>
                </TextBox>

            </Section>

            <Section id = "List5 sectionList">

                <TextBox>
                    <TextBoxContent
                        type = "4" 
                        aos = "fade-right"
                        title = "Entradas"
                    >
                        <TextBoxListItem txt = "É possível permitir a entrada de mercadoria via importação de XML" />
                        <TextBoxListItem txt = "Faça o pedido de compra de produto" />
                        <TextBoxListItem txt = "Obtenha o status de pedidos de compra" />
                    </TextBoxContent>
                    <TextBoxImg img = {Gestao_icon5} />
                </TextBox>

            </Section>

            <Section id = "List6 sectionList">

                <TextBox>
                    <TextBoxImg img = {Gestao_icon6} />
                    <TextBoxContent
                        type = "4" 
                        aos = "fade-left"
                        title = "Saídas"
                    >
                        <TextBoxListTitle txt = "Acompanhe os Pedidos" />
                        <TextBoxListItem txt = "Conferência no almoxarifado de todos os itens pedidos" />
                        <TextBoxListItem txt = "Acompanhamento de status de pedido" />

                        <TextBoxListTitle txt = "Vendas" />
                        <TextBoxListTxt txt = "Tenha um dashboard rápido e assertivo, todas as informações estarão em tempo real" />
                        <TextBoxListItem txt = "Nota Fiscal Eletrônica (NFe)" />
                        <TextBoxListItem txt = "Cotação de venda" />
                        <TextBoxListItem txt = "Obtenha o status de cotações" />
                        <TextBoxListItem txt = "Faça a emissão de nota fiscal de orçamento de forma simples e intuitiva" />

                    </TextBoxContent>
                </TextBox>

            </Section>

        </div>

    )

}

export function Supply(){

    return(

        <div className = "mainContent">

            <Helmet title = "Alta Supply" />

            <Section id = "Hero">

                <TextBox>

                    <TextBoxContent
                        type = "2" 
                        aos = "fade-right"
                        title = "ALTA Supply"
                        text = "O sistema ideal para ter em mãos todas as informações que sua transportadora necessita"
                    />
                    <TextBoxImg img = {Supply_img1} />

                </TextBox>

            </Section>

            <Section id = "About1">

                <TextBox>
                    <TextBoxImg img = {Supply_img2} />
                    <TextBoxContent
                        type = "2" 
                        aos = "fade-left"
                        title = "Domine o Mercado"
                        text = "O Alta Supply tem tudo o que você precisa para mudar a forma que sua transportadora conduz sua gestão de cargas"
                    />
                </TextBox>

            </Section>

            <Section id = "About3">

                <ProdutoSection title = "Acompanhamento de Entrega">

                    <ProdutoSectionItem txt = "Acompanhamento completo através do APP Alta Supply" />
                    <ProdutoSectionItem txt = "Motoristas podem fazer a baixa de entrega através do aplicativo, livrando-se da papelada" />
                    
                </ProdutoSection>

            </Section>

            <Section id = "About4">

                <ProdutoSection title = "Gestão de Baixas">

                    <ProdutoSectionItem txt = "Todas as baixas serão feitas de forma online pelo APP" />
                    <ProdutoSectionItem txt = "Informações já ficam armazenadas no sistema, onde a transportadora pode ter acesso a todos estes dados" />
                    
                </ProdutoSection>

            </Section>

            <Section id = "About5">

                <ProdutoSection title = "Gráficos em Tempo Real">

                    <ProdutoSectionItem txt = "Gráficos que mostram as informações em tempo real sobre o status de carga" />
                    <ProdutoSectionItem txt = "Gestão de baixas e cargas em depósito" />
                    <ProdutoSectionItem txt = "Insights inteligentes e melhorias em seu negócio" />

                </ProdutoSection>

            </Section>

        </div>

    )

}

export function PDV(){

    return(

        <div className = "mainContent">

            <Helmet title = "Alta PDV" />

            <Section id = "Hero">

                <TextBox>

                    <TextBoxContent
                        type = "2" 
                        aos = "fade-right"
                        title = "ALTA PDV"
                        text = "Seu ponto de venda rápido e prático!"
                    />
                    <TextBoxImg img = {PDV_img1} />

                </TextBox>

            </Section>

            <Section id = "About1">

                <TextBox>
                    <TextBoxImg img = {PDV_img2} />
                    <TextBoxContent
                        type = "2" 
                        aos = "fade-left"
                        title = "Fácil, Completo e Eficaz"
                        text = "Conheça o ALTA PDV, o sistema que irá tornar sua operação de venda na Frente de Caixa, simples e otimizada"
                    />
                </TextBox>

            </Section>

            <Section id = "About2">

                <TextDoubleBox>

                    <TextDoubleBoxContent 
                        title = "Gestão Online e Offline" 
                        text = "A operação não deve parar, para isso você pode contar com o Alta PDV, o sistema funcionará de forma online e offline, evitando problemas com a desconexão de internet"
                    />
                    <TextDoubleBoxContent 
                        title = "Emissão de Documento Fiscal" 
                        text = "Com o Alta PDV é possível fazer a emissão facilitada do cupom fiscal"
                    />

                </TextDoubleBox>

            </Section>

            <Section id = "About3">

                <h2> Funcionalidades </h2>

                <ul className = "boxGroup">

                    <FuncBox icon = "desktop_windows" txt = "Tenha a interface personalizada para diferentes segmentos de varejo" />
                    <FuncBox icon = "wifi_off" txt = "Frente de caixa que funciona de forma off-line" />
                    <FuncBox icon = "touch_app" txt = "Adaptado para tela touch" />
                    <FuncBox icon = "print" txt = "Emissão de cupom fiscal (NFC-e)" />
                    <FuncBox icon = "show_chart" txt = "Dashboard para resumo de vendas com critérios variados" />

                </ul>

            </Section>

            <Section id = "About4">

                <ProdutoSection title = "Controle de Estoque">

                    <ProdutoSectionItem txt = "Importação em formato XML" />
                    <ProdutoSectionItem txt = "Inventario de Estoque (Manual ou Importação de CSV)" />
                    <ProdutoSectionItem txt = "Lançamentos de Estoque (Manual ou Importação de CSV)" />

                </ProdutoSection>

            </Section>

            <Section id = "About5">

                <ProdutoSection title = "Controle de Carteira">

                    <ProdutoSectionItem txt = "Lançamento do Fiado atrelado ao cadastro de cliente" />
                    <ProdutoSectionItem txt = "Recebimento parcial diretamente na tela do PDV" />
                    <ProdutoSectionItem txt = "Consulta e imprime lançamentos e recebimentos" />

                </ProdutoSection>

            </Section>

            <Section id = "About6">

                <TextDoubleBox>

                    <TextDoubleBoxContent 
                        title = "Controle Financeiro" 
                        text = "Os registros de venda são feitos no PDV, e assim possibilitam o controle de fluxo de caixa"
                    />
                    <TextDoubleBoxContent 
                        title = "Produtos" 
                        text = "Indicação de produtos para balança. (Carga Toledo / Filizola) e leitura de balança de caixa-Cadastro ilimitado de tabelas de preço. Associação de diversos códigos para o mesmo item"
                    />

                </TextDoubleBox>

            </Section>

        </div>

    )

}

function FuncBox(props){

    return(

        <li className = "box" data-aos = "fade-left">

            <span className = "material-icons"> {props.icon} </span>
            <p> {props.txt} </p>

        </li>

    )

}

function ProdutoSection(props){

    return(

        <div className = "produtoSectionContainer">

            <div className = "produtoSectionTitle">
                <h3> {props.title} </h3>
                <span></span>
            </div>
            <ul className = "produtoSectionList"> {props.children} </ul>
            {/*<div className = "produtoSectionImg"></div>*/}

        </div>

    )

}

function ProdutoSectionItem({ txt }){

    return <li className = "produtoSectionListItem"> {txt} </li>

}