import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import $ from 'jquery'

import '../css/Produtos.css'

import Section from '../components/Section'
import { Frameset, Frame } from '../components/Frameset'
import { TextBox, TextBoxImg, TextBoxContent } from '../components/TextBox'

import BG1 from '../media/gestao/gestao_1.png'
import PDV_img1 from '../media/pdv/pdv_1.png'
import SUPPLY_img1 from '../media/supply/supply_1.png'

function Produtos(){

    const products = [
        {id: "1", name: "ALTA Gestão", icon: "desktop_windows", link: "/gestao"},
        {id: "2", name: "ALTA Supply", icon: "local_shipping", link: "/supply"},
        {id: "3", name: "ALTA PDV", icon: "storefront", link: "/pdv"},     
    ]

    useEffect(() => {

        $('#boxProdutos1').addClass('active')

        $('.boxProdutos').on('click', function(){

            let id = $(this).attr('data-product'), frame

            $('.boxProdutos').removeClass('active')
            $('.frameProdutos').removeClass('active')

            $(this).addClass('active')

            if(id === "1") frame = $('#frameProdutos0')

            if(id === "2") frame = $('#frameProdutos1')

            if(id === "3") frame = $('#frameProdutos2')

            frame.addClass('active')

        })

    })

    return(

        <div className = "mainContent">

            <Section id = "Header">

                <h1> Produtos </h1>
                <h2> Conheça as nossas soluções </h2>

            </Section>

            <Section id = "Product">

                <ul className = "boxProdutosContainer">

                    {products.map((data, index) => 
                    
                        <Box key = {index} id = {data.id} icon = {data.icon} name = {data.name} />
                    
                    )}

                </ul>

            </Section>

            <Section id = "Main">

                <Frameset id = "Produtos">

                    <Frame num = "0" path = "Produtos">

                        <FrameProduto type = "1" />

                    </Frame>

                    <Frame num = "1" path = "Produtos">

                        <FrameProduto type = "2" />

                    </Frame>

                    <Frame num = "2" path = "Produtos">

                        <FrameProduto type = "3" />

                    </Frame>

                </Frameset>

            </Section>

        </div>

    )

    function Box(props){

        return(

            <li id = {"boxProdutos"+props.id} className = "boxProdutos" data-product = {props.id}>

                <span className = "material-icons"> {props.icon} </span>

                <h3> {props.name} </h3>
                
            </li>

        )

    }

    function FrameProduto(props){

        if(props.type === "1"){

            return(

                <>
                    <TextBox>      
                        <TextBoxContent
                            type = "3"  
                            title = "ALTA GESTÃO"
                            text = "Mude a forma que você gerencia seu negócio!"
                            btn = "Conheça o Gestão"
                            link = "/produto/gestao"
                        />
                        <TextBoxImg img = {BG1} />
                    </TextBox>
                </> 

            )

        }

        if(props.type === "2"){

            return(

                <>
                    <TextBox>
                        <TextBoxContent
                            type = "3" 
                            title = "ALTA Supply"
                            text = "O sistema ideial para ter em mãos todas as informações que sua transportadora necessita, preenchendo todos os requisitos para uma gestão fácil e eficiente de tracking de cargas."
                            btn = "Conheça o Supply"
                            link = "/produto/supply"
                        />
                        <TextBoxImg img = {SUPPLY_img1} />
                    </TextBox>
                </>

            )

        }

        if(props.type === "3"){

            return(
  
                <>
                    <TextBox>
                        <TextBoxContent
                            type = "3" 
                            title = "ALTA PDV"
                            text = "Conheça o ALTA PDV, o sistema que irá tornar sua operação de venda na Frente de Caixa, simples e otimizada."
                            btn = "Conheça o PDV"
                            link = "/produto/pdv"
                        />
                        <TextBoxImg img = {PDV_img1} />
                    </TextBox>
                </>

            )

        }

    }

}

export default Produtos