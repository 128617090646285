import React from 'react'
import { Link } from 'react-router-dom'

/*import Typewriter from 'typewriter-effect/dist/core'
import { Carousel } from '3d-react-carousal'
import { Tooltip } from '@material-ui/core'
*/
import '../css/Index.css'

import Section from '../components/Section'

import BG1 from '../media/alta-2.png'
import BG2 from '../media/alta-1.png'
import Icon1 from '../media/icon1.png'
import Icon2 from '../media/icon2.png'
import Icon3 from '../media/icon3.png'

function Index(){
    /*
    const slides = [

        <Card
            icon = {Icon1}
            title = "PDV Alta"  
            txt = "Esse sistema irá tornar sua operação de venda na Frente de Caixa, simples e otimizada."
        />,
        <Card 
            icon = {Icon2}
            title = "SUPPLYTRACK"
            txt = "O sistema ideial para ter em mãos todas as informações que sua transportadora necessita, preenchendo todos os requisitos para uma gestão fácil e eficiente de tracking de cargas."
        />,
        <Card
            icon = {Icon3}
            title = "ERP ALTA GESTÃO" 
            txt = "Dentro deste software completo, você tem acesso a módulos que são responsáveis por toda parte gerencial de sua empresa. Como: financeiro, fiscal, estoque e outras funções."
        />

    ]
    
    function Card(props){

        return(

            <ul className = "cardContainer">

                <li className = "cardContent">
                    <img src = {props.icon} alt = "Imagem" />
                </li>

                <li className = "cardContent">
                    <h3 className = "cardTitle"> {props.title} </h3>
                    <p className = "cardText"> {props.txt} </p>
                </li>
                
            </ul>

        )

    }
    */
    function CardContainer(props){

        function Card(props){

            return(

                <Link to = {'/produto/'+props.link}>

                    <ul className = "cardBox">
        
                        <li className = "cardContent img">
                            <img src = {props.icon} alt = "Imagem" />
                        </li>
        
                        <li className = "cardContent">
                            <h2 className = "cardTitle"> {props.title} </h2>
                            <p className = "cardText"> {props.txt} </p>
                        </li>
                        
                    </ul>

                </Link>
    
            )

        }

        return(

            <div className = "cardContainer">

                <Card
                    link = "gestao"
                    icon = {Icon3}
                    title = "ALTA Gestão" 
                    txt = "Um software completo, com acesso a módulos que são responsáveis por toda parte gerencial de sua empresa, como: financeiro, fiscal, estoque e outras funções."
                />
                <Card
                    link = "supply"
                    icon = {Icon2}
                    title = "ALTA Supply"
                    txt = "O sistema ideal para ter em mãos todas as informações que sua transportadora necessita, preenchendo todos os requisitos para uma gestão fácil e eficiente de tracking de cargas."
                />
                <Card
                    link = "pdv"
                    icon = {Icon1}
                    title = "ALTA PDV"  
                    txt = "Esse sistema irá tornar sua operação de venda na Frente de Caixa, simples e otimizada."
                />
            
            </div>

        )

    }

    function MVV(props){

        return(

            <ul className = "mvvContent">

                <li className = "mvvBox">
                    <h3> {props.title} </h3>
                </li>
                <li className = "mvvBox" data-aos = "fade-left">
                    {props.children}
                </li>

            </ul>

        )

    }

    function ValoresContainer(props){

        return <ul className = "listContainer"> {props.children} </ul>

    }

    function ValoresContent(props){

        return(

            <li className = "listContent">

                <span className = "material-icons"> done </span>
                <p> {props.txt} </p>

            </li>

        )

    }
    /*
    useEffect(() => {
        
        var typewriter = new Typewriter('#indexTW', {

            loop: false,
            delay: 75,

        });

        typewriter
            .typeString('Mudando o conceito da sua empresa através da tecnologia')
            .changeDelay(500)
            .typeString('...')
            .pauseFor(500)
            
            .deleteChars(3)
            .pauseFor(750)
            .changeDelay(100)
            .typeString('<strong> Alta Soluções!</strong>')
            .start();
        
    })
    */
    return(

        <div className = "mainContent">

            <div data-aos = "fade-in">
                <Section id = "Header">

                    <h1 id = "indexTW"> Mudando o conceito da sua empresa através da tecnologia </h1>

                </Section>
            </div>
            
            <Section id = "About">

                <ul className = "aboutContent textBox light">

                    <li className = "text" data-aos = "fade-right">
                        <h2> Sobre nós </h2>
                        <p>
                            Somos uma empresa focada em fornecer tecnologia para melhorar a gestão dos nossos clientes, 
                            atuamos de forma estratégica para oferecer soluções em software para as áreas de Gestão Empresarial, 
                            Logística, Varejo, Gestão de Acesso e Segurança.
                        </p>
                    </li>
                    <li className = "img" data-aos = "flip-up" data-aos-duration = "800">
                        <img src = {BG2} alt = "banner" />
                    </li>

                </ul>

            </Section>

            <Section id = "SW">

                <ul className = "swContent textBox">

                    <li className = "img" data-aos = "flip-up" data-aos-duration = "800">
                        <img src = {BG1} alt = "banner" />
                    </li>
                    <li className = "text" data-aos = "fade-left">

                        <h2> Softwares personalizados para sua empresa </h2>
                        <p>
                            Para manter sua empresa sempre conectada, conte com as ferramentas
                            certas, gerenciando de forma rápida e objetiva os processos de sua empresa
                            com aplicações mobile nativas, híbridas e web responsivas.
                        </p>

                    </li>

                </ul>

            </Section>

            <Section id = "Products">

                <h1> Nossas Soluções </h1>

                <p>
                    Conte com a experiência de mais de 5 anos da Alta Soluções, atendendo
                    demandas específicas e entregando softwares de grande valor agregado.
                </p>

                <CardContainer />

            </Section>

            <Section id = "MVV">

                <MVV title = "Missão">
                    <p> Entender as necessidades de nossos clientes e oferecer as melhores soluções no mercado, conceder aos clientes um atendimento de qualidade e um acompanhamento técnico especializado. </p>
                </MVV>

                <MVV title = "Visão">
                    <p> Tornar-se referência nacional no ramo de Softwares e Aplicações Mobile. </p>
                </MVV>

                <MVV title = "Valores">
                    <ValoresContainer>
                        <ValoresContent txt = "Ética e respeito ao cliente" />
                        <ValoresContent txt = "Comprometimento" />
                        <ValoresContent txt = "Responsabilidade" />
                        <ValoresContent txt = "Satisfação em criar soluções" />
                        <ValoresContent txt = "Trabalho em equipe e inovação" />
                        <ValoresContent txt = "Transparência nas relações empresa x cliente" />
                    </ValoresContainer>
                </MVV>

            </Section>

        </div>

    )

}

export default Index;