import React from 'react'
import Helmet from 'react-helmet'

export default function Error(props){

    return(

        <>

            <Helmet title = {props.helmet} />

            <div id = "errorWrapper">

                <h1> 404 </h1>
                <p> Página não encontrada :( </p>
                <a href = "/"> Voltar à Home </a>

            </div>

        </>

    )

}