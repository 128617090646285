import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import Logo from '../media/logo2.png'

function Footer(){

    function FooterYear(){

        let ano = new Date()

        return ano.getFullYear()

    }

    function FooterContainer(props){

        return(

            <footer>

                <ul className = "footerContainer"> {props.children} </ul>

            </footer>

        )

    }

    function FooterLink(props){

        return(

            <Tooltip title = {props.tooltip} arrow>
                <Link to = {props.link}> {props.txt} </Link>
            </Tooltip>

        )

    }

    function FooterSocial(props){

        return(

            <Tooltip title = {'Acessar '+props.tooltip} arrow>
                <a href = {props.link} id = {props.id} className = {props.cn} target = "_blank"  rel = "noreferrer">
                    <FontAwesomeIcon icon = {props.icon} />
                </a>
            </Tooltip>

        )

    }

    function Box(props){

        return <div className = {'box '+props.cn}> {props.children} </div>

    }

    return(

        <FooterContainer>

            <li className = "footerContent top">

                <Box cn = "banner">

                    <Tooltip title = "Home" arrow>
                        <Link to = "/">
                            <img src = {Logo} alt = "logo" />
                        </Link>
                    </Tooltip>

                </Box>

                <Box cn = "links">

                    <FooterLink link = "/" tooltip = "Home" txt = "Home" />
                    <FooterLink link = "/produtos" tooltip = "Produtos" txt = "Produtos" />
                    <FooterLink link = "/sobre" tooltip = "Sobre Nós" txt = "Sobre nós" />

                </Box>

                <Box cn = "social">

                    <FooterSocial tooltip = "Whatsapp" link = "https://api.whatsapp.com/send?phone=5511996049933" cn = "iconWhatsapp" icon = {faWhatsapp} />
                    <FooterSocial tooltip = "Instagram" link = "https://www.instagram.com/altasolucoes/" cn = "iconInstagram" icon = {faInstagram} />
                    <FooterSocial tooltip = "Facebook" link = "https://www.facebook.com/altasolucoes" cn = "iconFacebook" icon = {faFacebook} />

                </Box>

            </li>
            
            <li className = "footerContent bottom">

                <Box cn = "copyright">

                    <p> Alta Soluções© <FooterYear />, todos os direitos reservados. </p>

                </Box>

            </li>

        </FooterContainer>

    )

}

export default Footer