import React from 'react'

export function Frameset(props){

    return <ul id = {"frameset"+props.id} className = "frameset"> {props.children} </ul>
    
}

export function Frame(props){

    if(props.num === "0" || props.num === 0){

        return(

            <FrameContent id = {'frame'+props.path+props.num} cn = {"frame frame"+props.path+" active"}>
                {props.children}
            </FrameContent>
    
        )

    }else{

        return(

            <FrameContent id = {'frame'+props.path+props.num} cn = {"frame frame"+props.path}>
                {props.children}
            </FrameContent>
    
        )

    }

    function FrameContent(props){

        return(

            <li id = {props.id} className = {props.cn}>
    
                <div className = "frameContent"> {props.children} </div>
    
            </li>

        )

    }

}