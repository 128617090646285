import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Helmet } from 'react-helmet'
import AOS from 'aos'
import 'aos/dist/aos.css'

import ScrollTop from './ScrollTop'
import Header from './Header'
import Footer from './Footer'
import Loader from './Loader'

export default function Main(props){

    const [load, setLoad] = useState(true)

    useEffect(() => {setLoad(false)}, [])

    useEffect(() => {
        AOS.init({
            duration: 1500,
            once: true
        })
    }, [])

    const Position = () => $('#headerSideBar').css('left', '-240px')

    return(

        <>
            {load && <Loader /> || ( 
                <div id = "wrapper">
                    <Helmet>
                        <title> {props.helmet} </title>
                        <meta name = "description" content = {props.description} />
                    </Helmet>
                    <ScrollTop />
                    <Header />
                    <main id = {"main"+props.id} onClick = {() => Position()}> {props.children} </main>
                    <Footer />
                </div>
            )}
        </>

    )
    
}