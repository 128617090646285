import React from 'react'

import Loading from '../media/load.svg'

function Loader(){

    return(

        <div id = "wrapperLoader">

            <img src = {Loading} alt = "Carregando..." />

        </div>

    )

}

export default Loader