import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import $ from 'jquery'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faComments } from '@fortawesome/free-solid-svg-icons'

import Banner from '../media/banner4.png'
import { useEffect } from 'react'

function Header(){

    const cn = "headerContent", cnItem = "headerItem"

    const products = [
        {id: "3", name: "Gestão", icon: "desktop_windows", link: "gestao"},
        {id: "2", name: "Supply", icon: "local_shipping", link: "supply"},
        {id: "1", name: "PDV", icon: "storefront", link: "pdv"},     
    ]

    return(

        <HeaderContainer>

            <HeaderNavigation />

            <HeaderBanner id = "headerBanner" cn = {cn} />

            <li id = "headerLinks" className = "headerContent">

                <HeaderLinksItem cn = {cnItem} link = "/" txt = "Home" />
                <HeaderItem cn = {cnItem} txt = "Produtos">

                    <DropdownContainer id = "dropdownProdutos" title = "Nossos Produtos">

                        {products.map((data, index) =>
                            <DropdownItem key = {index} txt = {data.name} link = {data.link ? '/produto/'+data.link : ''}>
                                <DropdownIcon icon = {data.icon} />
                            </DropdownItem>
                        )}

                    </DropdownContainer>

                </HeaderItem>
                <HeaderItem cn = {cnItem} txt = "Serviços">

                    <DropdownContainer id = "dropdownServicos" title = "Nossos Serviços">

                        <DropdownItem txt = "Mobile" link = "/mobile">
                            <DropdownIcon icon = "smartphone" />
                        </DropdownItem>
                        <DropdownItem txt = "Fábrica de Software" link = "/fabrica-software">
                            <DropdownIcon icon = "psychology" />
                        </DropdownItem>

                    </DropdownContainer>

                </HeaderItem>
                <HeaderLinksItem cn = {cnItem} link = "/sobre" txt = "Sobre nós" />          
                <HeaderLinksItem type = "blank" cn = {cnItem} link = "https://www.blogaltasolucoes.com.br/" txt = "Blog" />
                <HeaderLinksItem type = "blank" border = {true} cn = {cnItem} link = "http://www-altasolucoes-com-br-1.rds.land/converse-com-um-especialista" txt = "Fale com um especialista" />

            </li>

            <HeaderIcons id = "headerIcons" cn = {cn} />

        </HeaderContainer>

    )

    function HeaderContainer(props){

        return(

            <header>

                <nav>

                    <ul className = "headerContainer"> {props.children} </ul>

                </nav>

                <HeaderSideBar>

                    <HeaderSideBarSection>
                        <HeaderSideBarItem link = "/" label = "Home" />
                        <HeaderSideBarItem link = "/sobre" label = "Sobre nós" />
                        <HeaderSideBarItem blank = {true} link = "https://www.blogaltasolucoes.com.br/" label = "Blog" />
                    </HeaderSideBarSection>

                    <HeaderSideBarSection title = "Produtos">
                        <HeaderSideBarItem link = "/produto/gestao" label = "Gestão" />
                        <HeaderSideBarItem link = "/produto/supply" label = "Supply" />
                        <HeaderSideBarItem link = "/produto/pdv" label = "PDV" />
                    </HeaderSideBarSection>

                    <HeaderSideBarSection title = "Serviços">
                        <HeaderSideBarItem link = "/mobile" label = "Mobile" />
                        <HeaderSideBarItem link = "/fabrica-software" label = "Fábrica de Software" />
                    </HeaderSideBarSection>

                </HeaderSideBar>

            </header>

        )

    }

    function HeaderNavigation(){

        useEffect(() => {

            const Sidebar = $('#headerSideBar')

            $('#headerNavIcon').on('click', function(){

                let Sidebar_Position = $('#headerSideBar').css('left')

                Sidebar.css('left', Sidebar_Position === '0px' ? '-240px' : '0px')
                
            })

        }, [])

        return <span id = "headerNavIcon" className = "material-icons"> menu </span>

    }

    function HeaderSideBar(props){

        return <div id = "headerSideBar"> {props.children} </div>

    }

    function HeaderSideBarSection(props){

        return(

            <ul className = "headerSideBarSection">
                {props.title ? <li><h2> {props.title} </h2></li> : null}
                {props.children}
            </ul>

        )

    }

    function HeaderSideBarItem(props){

        return(

            <li className = "headerSideBarItem">
                {props.blank ? <a href = {props.link} target = "_blank"> {props.label} </a> : <Link to = {props.link}> {props.label} </Link>}
            </li>

        )

    }

    function HeaderBanner(props){

        return(

            <li id = {props.id} className = {props.cn}>

                <Tooltip title = "Home" arrow>
                    <Link to = "/">
                        <img src = {Banner} alt = "Banner" />
                    </Link>
                </Tooltip>

            </li>

        )

    }

    function HeaderItem(props){

        const [open, setOpen] = useState(false)

        return(

            <span 
                className = {props.cn}
                onMouseOver = {() => DropEvent(true)} 
                onMouseLeave = {() => DropEvent(false)}
            >

                {props.txt}
                {open && props.children}

            </span>

        )

        function DropEvent(props){

            setOpen(props)

        }

    }

    function HeaderLinksItem(props){

        return(

            <>
                {props.type === 'blank' ? (
                    <a className = {`${props.cn} ${props.border ? 'border' : ''}`} href = {props.link} target = "_blank"> {props.txt} </a>
                ) : (
                    <Tooltip title = {props.txt}>
                        <Link className = {props.cn} to = {props.link}> {props.txt} </Link>
                    </Tooltip>
                )}
            </>

        )

    }

    function DropdownContainer(props){

        return(

            <div className = "dropdownContainer">

                <h1> {props.title} </h1>

                <ul id = {props.id} className = "dropdownItensContainer"> {props.children} </ul>

            </div>

        )

    }

    function DropdownItem(props){

        return(

            <li className = "dropdownItemContent">

                <Link to = {props.link}> {props.children}{props.txt} </Link>
                
            </li>

        )

    }

    function DropdownIcon(props){

        return <span className = "material-icons"> {props.icon} </span>

    }

    function HeaderIcons(props){

        return(

            <li id = {props.id} className = {props.cn}>
                <BrandIcon tooltip = "Acessar Whatsapp" link = "https://api.whatsapp.com/send?phone=5511996049933" id = "headerWhatsapp" icon = {faWhatsapp} />
                <BrandIcon tooltip = "Acessar Instagram" link = "https://www.instagram.com/altasolucoes/" id = "headerInstagram" icon = {faInstagram} />
                <BrandIcon tooltip = "Acessar Facebook" link = "https://www.facebook.com/altasolucoes" id = "headerFacebook" icon = {faFacebook} />
                <BrandIcon tooltip = "Converse com um especialista" link = "http://www-altasolucoes-com-br-1.rds.land/converse-com-um-especialista" id = "headerLandingPage" icon = {faComments} />
            </li>

        )

        function BrandIcon(props){

            return(

                <Tooltip title = {props.tooltip} arrow>
                    <a href = {props.link} id = {props.id} className = {props.cn} target = "_blank"  rel = "noreferrer">
                        <FontAwesomeIcon icon = {props.icon} />
                    </a>
                </Tooltip>
    
            )
    
        }

    }

}

export default Header