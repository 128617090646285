import React from 'react'
import Helmet from 'react-helmet'

import '../css/FSW.css'

import Section from '../components/Section'
import { TextBox, TextBoxImg, TextBoxContent } from '../components/TextBox'

import FSW_img0 from '../media/alta-2.png'
import FSW_img1 from '../media/fsw/fsw_1.png'
import FSW_img2 from '../media/fsw/fsw_2.png'
import FSW_img3 from '../media/fsw/fsw_3.png'
import FSW_img4 from '../media/fsw/fsw_4.png'
import FSW_img5 from '../media/fsw/fsw_5.png'
import FSW_img6 from '../media/fsw/fsw_6.png'
import FSW_img7 from '../media/fsw/fsw_7.png'
import FSW_img8 from '../media/fsw/fsw_8.png'
import FSW_img9 from '../media/fsw/fsw_9.png'

export default function FSW(){

    return(

        <div className = "mainContent">

            <Section id = "Hero">

                <TextBox>

                    <TextBoxContent type = "2" title = "Fábrica de Software" />
                    <TextBoxImg img = {FSW_img0} />

                </TextBox>

            </Section>

            <Section id = "About0">

                <div className = "aboveBox">

                    <TextBox>

                        <TextBoxImg img = {FSW_img1} />
                        <TextBoxContent 
                            type = "2" 
                            aos = "fade-in"
                            title = "Softwares personalizados para a sua empresa" 
                            text = "A Alta Soluções transforma o seu negócio através da tecnologia. Garanta raciocínio e agilidade para seu dia a dia. Conte com a experiência de mais de 5 anos da Alta Soluções atendendo demandas específicas e entregando softwares de grande valor agregado."
                        />      

                    </TextBox>

                </div>

            </Section>

            <Section id = "About1">

                <TextBox>

                    <TextBoxContent
                        type = "2"
                        aos = "fade-right"
                        title = "Design da Solução"
                        text = "Não deixe nenhum detalhe de seu projeto de fora, conte com a nossa equipe para antecipar a necessidade de sua empresa através do conhecimento de sua realidade. Fazemos um desenho criterioso do escopo, detalhando toda a solução. Dando total liberdade para avançar com o projeto."
                    />
                    <TextBoxImg img = {FSW_img2} />

                </TextBox>

            </Section>

            <Section id = "About2">

                <TextBox>

                    <TextBoxImg img = {FSW_img3} />
                    <TextBoxContent
                        type = "2"
                        aos = "fade-left"
                        title = "Desenvolvimento de Sistemas"
                        text = "Conte com as habilidades de nossos especialistas para criação de software com melhor custo-benefício. Nossos sistemas são desenvolvidos com a melhor tecnologia do mercado, o que garante um excelente resultado para nossos clientes."
                    />

                </TextBox>

            </Section>

            <Section id = "About3">

                <TextBox>

                    <TextBoxContent
                        type = "2"
                        aos = "fade-right"
                        title = "Mapeamento de Fluxo"
                        text = "É possível ter mapeado todos os fluxos internos de sua empresa, levando a uma automatização e maior rastreabilidade. Com o Gerenciamento de Processos de Negócios, você tornará sua equipe mais produtiva, estratégica e com foco nos resultados da empresa."
                    />
                    <TextBoxImg img = {FSW_img4} />

                </TextBox>

            </Section>

            <Section id = "About4">

                <TextBox>

                    <TextBoxImg img = {FSW_img5} />
                    <TextBoxContent
                        type = "2"
                        aos = "fade-left"
                        title = "Integração de Sistemas"
                        text = "Leve maior eficácia para seus processos e elimine bugs operacionais com a integração de sistemas da nossa fábrica de software. É possível integrar diversos sistemas de sua empresa e garantir a troca de informação de forma segura e assertiva."
                    />

                </TextBox>

            </Section>

            <Section id = "About5">

                <TextBox>

                    <TextBoxContent
                        type = "3"
                        aos = "fade-right"
                        title = "Desenvolvimento Mobile"
                        text = "Para manter sua empresa sempre conectada, conte com as ferramentas certas. Gerenciando de forma rápida e objetiva os processos de sua empresa com aplicações mobile nativas, híbridas e web responsivas."
                        btn = "Saiba mais"
                        link = "mobile"
                    />
                    <TextBoxImg img = {FSW_img6} />

                </TextBox>

            </Section>

            <Section id = "About6">

                <TextBox>

                    <TextBoxImg img = {FSW_img7} />
                    <TextBoxContent
                        type = "2"
                        aos = "fade-left"
                        title = "Internet das Coisas"
                        text = "Participe da revolução no mercado através da automatização de processos na sua empresa, conectando tecnologia, sensores e dispositivos à sistemas de informação. Tenha uma operação eficiente com monitoramento e gestão em tempo real."
                    />

                </TextBox>

            </Section>

            <Section id = "About7">

                <TextBox>

                    <TextBoxContent
                        type = "2"
                        aos = "fade-right"
                        title = "Relatórios"
                        text = "Os grandes volumes de informação de diversas fontes podem ser analisados de forma rápida através do BI. Passe a utilizar a ciência de dados para definir padrões, obter insights e ter acesso a dados analíticos de seu mercado de atuação."
                    />
                    <TextBoxImg img = {FSW_img8} />

                </TextBox>

            </Section>

            <Section id = "About8">

                <TextBox>

                    <TextBoxImg img = {FSW_img9} />
                    <TextBoxContent
                        type = "2"
                        aos = "fade-left"
                        title = "Sustentação de Software"
                        text = "Desenvolvemos o software de uma forma pensada na inclusão de novas funcionalidades ou exclusão de antigas. O que proporciona uma customização sem dores de cabeça, dentro de um sistema auto intuitivo de fácil usabilidade."
                    />

                </TextBox>

            </Section>

        </div>

    )

}