import React from 'react'
import { Tooltip } from '@material-ui/core'

export function TextBox(props){

    return <ul className = "textBox"> {props.children} </ul>

}

export function TextBoxImg(props){

    return <li className = "img" data-aos = "flip-up" data-aos-duration = "800"><img src = {props.img} alt = "Figura" /></li>

}

export function TextBoxContent(props){

    let AOS = props.aos ? props.aos : 'fade-in'

    if(props.type === "1"){

        return(

            <li className = "text" data-aos = {AOS}>
                <h2> {props.title} </h2>
                <p> {props.text} </p>
            </li>

        )

    }

    if(props.type === "2"){

        return(

            <li className = "text bold" data-aos = {AOS}>
                <h2> {props.title} </h2>
                <p> {props.text} </p>
            </li>
            
        )

    }

    if(props.type === "3"){

        return(

            <li className = "text bold" data-aos = {AOS}>
                <h2> {props.title} </h2>
                <p> {props.text} </p>
                <div className = "produtosOptions">
                    <Tooltip title = {props.btn} arrow>
                        <a href = {props.link} className = "btn style1"> {props.btn} </a>
                    </Tooltip>
                </div>
            </li>
            
        )

    }

    if(props.type === "4"){

        return(

            <li className = "text bold"  data-aos = {AOS}>
                <h2> {props.title} </h2>
                <ul className = "textBoxList"> {props.children} </ul>
            </li>
            
        )

    }

}

export function TextBoxListTitle(props){

    return <h3 className = "textBoxListTitle"> {props.txt} </h3>

}

export function TextBoxListTxt(props){

    return <span className = "textBoxListTxt"> {props.txt} </span>

}

export function TextBoxListItem(props){

    return <li className = "textBoxListItem"> {props.txt} </li>

}

export function TextDoubleBox(props){

    return <ul className = "textDoubleBox"> {props.children} </ul>

}

export function TextDoubleBoxContent(props){

    return(

        <li>
            <h2> {props.title} </h2>
            <p> {props.text} </p>
        </li>

    )

}